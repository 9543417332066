.wcm-toolbar {
  margin: 15px 0;

  background-color: #f8f8f8;
}

.wcm-toolbar nav {
  background-color: #f8f8f8;
  border-color: #f8f8f8;
}

.wcm-toolbar.wcm-toolbar-padded {
  padding: 10px 15px;
}

.wcm-toolbar st-pagination ul.pagination {
  margin: 0;
}

.wcm-toolbar st-pagination select {
  margin: 0 1ex;
}

.wcm-erp .page-header h1 {
  font-size: 2em;
}

.wcm-erp table thead th:not(.text-left):not(.text-right) {
  text-align: center;
}

.wcm-erp table .help-block {
  margin-bottom: 0;
}

.wcm-erp table tr.borderless th,
.wcm-erp table tr.borderless td {
  border: 0;
}

.wcm-erp a .badge {
  margin-left: 1em;
}

.wcm-erp .tab-pane {
  margin-bottom: 15px;
}

.wcm-erp .tab-pane {
  padding: 15px;
  padding-bottom: 0;

  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.wcm-erp .no-border-tab .tab-pane {
  padding: 15px;
  padding-bottom: 0;
  border: none;
}

.wcm-erp .form-group label:after {
  content: " :";
}

/*
.wcm-erp.modal-view input[disabled]
.wcm-erp.modal-view select[disabled]
.wcm-erp.modal-view textarea[disabled]
 */

.wcm-erp.modal-view {
    .form-control[disabled], .form-control.disabled, .input-group-addon[disabled] {
    box-shadow: none;
    border-color: transparent;
    background-color: transparent;
    cursor: default;
  }
}

/* make readonly fields look like disabled ones so we can use readonly, not disabled,
to allow for copying of values in firefox
*/
.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}

.wcm-erp.modal-view select.form-control[disabled] {
  -webkit-appearance: none;
     -moz-appearance: none;
}

.wcm-erp.modal-view select.form-control[disabled]::-ms-expand {
  display: none;
}

.text-truncated {
  display: inline-block;
  height: 1.4em;
  overflow: hidden;
  word-break: break-all;

  .text-muted {
    margin-left: 1em;
  }
}

.wcm-erp .well {
  background-color: #fafafa;

  border: 0;
  border-radius: 0;

  -webkit-box-shadow: none;
     -moz-box-shadow: none;
          box-shadow: none;
}

.wcm-erp .well > .form-group:last-child {
  margin-bottom: 0;
}

.modal-dialog.modal-sidebar-right {
  position: fixed;
  right: 0;
  top: 0;

  margin: 0;

  height: 100%;

  width: 888px;

  overflow-y: scroll;
}

.modal-dialog.modal-sidebar-right .modal-content {
  min-height: 100%;

  border: 0;
  border-radius: 0;

  -webkit-box-shadow: none;
     -moz-box-shadow: none;
          box-shadow: none;
}

.wcm-erp pre > code {
  white-space: pre;
}

.wcm-erp .div-striped > div:nth-child(odd) {
  background-color: #f9f9f9;
}

.wcm-erp .padded {
  padding: 10px 15px;
}

.wcm-erp .form-control{
  color: #333;
}
