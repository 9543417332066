/* You can add global styles to this file, and also import other style files */
@import "app/core/globals/wcm-table/global-wcm-table-style.less";

@import "assets/css/bootstrap-variables.less";
@import "assets/css/google-fonts-lato.css";
@import "assets/css/waycom-common.less";
@import "assets/css/wira.less";
@import "assets/css/bootstrap-submenu.less";
@import "assets/css/tribute.css";

:root {
  --primary-50: #f2fafc;
  --primary-100: #e6f3f7;
  --primary-200: #c2e0ed;
  --primary-300: #a1cde3;
  --primary-400: #66a3cc;
  --primary-500: #337ab7;
  --primary-600: #2968a6;
  --primary-700: #1d508a;
  --primary-800: #133a6e;
  --primary-900: #0b2652;

  --gray-50: #eef1f2;
  --gray-100: #dde2e4;
  --gray-200: #bbc5ca;
  --gray-300: #98a8af;
  --gray-400: #768b95;
  --gray-500: #546e7a;
  --gray-600: #435862;
  --gray-700: #324249;
  --gray-800: #222c31;
  --gray-900: #111618;

  --info: #5bc0de;
  --success: #5cb85c;
  --warning: #f0ad4e;
  --danger: #d9534f;
}

.no-text-wrap {
  text-wrap: nowrap;
}

.no-margin {
  margin: 0 !important;
}

/* add !important to the [hidden] attribute,
   otherwise it cannot be used on the bootstrap
   buttons because they redefine their display attribute */
[hidden] {
  display: none !important;
}

// fix for firefox : Disabled elements don't fire mouse events
input[disabled] {pointer-events:none}

/* this is necessary to avoid making the hamburger menu on the field take full
   width when you click on it */
.input-group.show.dropdown {
  display: table !important;
}

/* these lines is for the custom field components input*/
.wcm-highlight-req.ng-invalid[required] > .input-group > .form-control,
.wcm-highlight-error > .input-group > .form-control,
/* this line is for the custom field components select button */
.wcm-highlight-req.ng-invalid[required] > .input-group > .input-group-btn > .btn,
.wcm-highlight-req.form-control.ng-invalid[required],
.wcm-highlight-invalid.form-control.ng-invalid,
/* this line if for the custom input (e.g. decimal input) */
.wcm-highlight-req.ng-invalid[required] .form-control,
/* this line is for the decimal field */
.wcm-highlight-error .form-control,
.wcm-highlight-error {
  &:not([disabled]):not([readonly]):not(.disabled) {
    border-color: #ce8483;

    &:focus {
      border-color: #843534;
      -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px #ce8483;
      box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px #ce8483;
    }
  }
}

.wcm-xs-btn {
  min-width: 24px;
}

.wcm-p-radio-wrapper {
  margin-bottom: 5px;
  p-radioButton {
      margin-right: 5px;
  }
  label {
    cursor: pointer;
    font-weight: initial;
  }
  label:after {
    content: initial!important;
  }
}

.wcm-collapsible-header, .panel-heading {
  cursor: pointer;
  .fa-chevron-right {
    margin-right: 5px;
  }
}

.wcm-tab-header {
  background-color: #f4f4f4;
  height: 50px;
  line-height: 50px;
  padding: 0 15px;
  margin-bottom: 15px;
}

.wcm-like-pre {
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 4px;
  border: 1px #ccc solid;
  font-family: monospace;
  font-size: 0.9em;
  ul {
    margin-top: 10px;
  }
}

.wcm-selection-bar {
  position: sticky;
  bottom: 0px;
  pointer-events: none;

  .bar-wrapper {
    pointer-events: auto;
    padding: 12px 15px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background: white;
    -moz-box-shadow: 0 0 12px #999999;
    -webkit-box-shadow: 0 0 12px #999999;
    box-shadow: 0 0 12px #999999;
  }

  .selection-label {
    padding-right: 10px;
  }

  .action-btn-bar {
    button {
      margin-top: 3px;
      margin-bottom: 3px;
    }
  }
}

.wcm-stacked-modal-lvl-0 {
  z-index: 1065 !important;
}

.wcm-stacked-modal-lvl-0-backdrop {
  z-index: 1064 !important;
}

.wcm-stacked-modal-lvl-1 {
  z-index: 1080 !important;
}

.full-size-img {
 max-width: 100%;
}

// this rule is for the required asterisk in the nav tab title
.nav-tabs .nav-link i.required {
  color: red;
}

/* ng-bootstrap dropdown css class (usually defined by bootstrap 4) */
.btn.dropdown-toggle-split {
  padding-right: 8px;
  padding-left: 8px;
}

.wcm-bs4-dropdown.dropdown.show {
  display: inline-block !important;
}

.navbar-right .wcm-bs4-dropdown .dropdown-menu {
  right: auto;
  left: auto;
  bottom: auto;
  // this prevent the blur for the dropdown content in chrome
  will-change: auto !important;
}

/* ngb-popover */
.popover-body hr {
  margin-top: 4px;
  margin-bottom: 8px;
}

/* nav link badges */
.nav-tabs .nav-link .badge{
  margin-left: 0px !important;
  margin-top: -4px !important;

  /* todo bootstrap4 : use .badge-primary, .badge-success, .badge-danger, .badge-light */
  &.primary {
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;
  }
  &.success {
    color: #fff;
    background-color: #12A70E;
    border-color: #149410;
  }
  &.danger {
    color: #fff;
    background-color: #B80D1D;
    border-color: #8F0A16;
  }
  &.light {
    color: #969696;
    background-color: #f5f5f5;
  }
}

/* progress bar */
.progress-bar-text-center {
  position: relative;
  padding-top: 4px;

  .progress-bar-text {
    position: absolute;
    width: 100%;
    color: #333;
    text-align: center;
    font-weight: bold;
  }
}

/* will be defined in bootstrap4 */
.font-weight-bold {
  font-weight: bold;
}


/* Suggestion toast */
#toast-container > .toast-info.ngx-toastr.suggestion-toast {
  opacity: 1;
  width: 54px;
  height: 40px;
  padding-top: 10px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  div.toast-title, button{
    opacity: 0;
  }
}

// @media (min-width: 400px) {
  #toast-container > .toast-info.ngx-toastr.suggestion-toast:hover{
    -webkit-transition: width 0.2s ease-in-out;
    -moz-transition: width 0.2s ease-in-out;
    -o-transition: width 0.2s ease-in-out;
    transition: width 0.2s ease-in-out;
    opacity: 1;
    width: 190px;
    height: 40px;
    padding-top: 10px;
    padding-bottom: 0px;
    padding-left: 50px;
    padding-right: 15px;
    div.toast-title, button{
      -webkit-transition: opacity 0.2s 0.2s;
      -moz-transition: opacity 0.2s 0.2s;
      -o-transition: opacity 0.2s 0.2s;
      transition: opacity 0.2s 0.2s;
      opacity: 1;
    }
  }
// }

// .toast-info.ngx-toastr.suggestion-toast {
//   background-color: #149410;
// }

/* font style with same color as boostrap buttons */
.wcm-danger{
  color: var(--danger);
}
.wcm-warning{
  color: var(--warning);
}
.wcm-success{
  color: var(--success);
}
.wcm-info{
  color: var(--info);
}

.label-wcm-success-bright{
  background-color: #81d681;
}

.cond-click{
  cursor: pointer;

  &[disabled]{
    cursor: default;
  }
}

.btn.wrap-text{
  white-space: initial;
}

textarea.vertical-resize{
  resize: vertical;
}

textarea.resize-when-editing[disabled]{
  resize: none;
}
/* wcm-toolbar */
.wcm-toolbar {
  margin: 15px 0;
  background-color: #f8f8f8;
}

.wcm-toolbar nav {
  background-color: #f8f8f8;
  border-color: #f8f8f8;
}

.wcm-toolbar.wcm-toolbar-padded {
  padding: 10px 15px;
}

.wcm-toolbar st-pagination ul.pagination {
  margin: 0;
}

.wcm-toolbar st-pagination select {
  margin: 0 1ex;
}




/* flex tools */
.flex {
  display: flex;

  &.center {
    align-items: center;
    justify-content: center;
  }
}
.flex-left {
  align-self: start;
  flex: 1;

  &.no-fill {
    flex-grow: 0;
  }

  &.flex-v-center {
    align-self: center;
  }
}
.flex-right {
  align-self: end;

  &.fill {
    flex-grow: 1;
  }

  &.flex-v-center {
    align-self: center;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;

  &.center {
    align-items: center;
    justify-content: center;
  }
}

.space-between {
  justify-content: space-between !important;
}

.flex-first-item{
  order: 1;
}
.flex-second-item{
  order: 2;
}

.flex-wrap{
  flex-wrap: wrap;
}

.gap-0 {
  gap: 0 !important;
}
.gap-1 {
  gap: .25rem !important;
}
.gap-2 {
  gap: .5rem !important;
}
.gap-3 {
  gap: 1rem !important;
}
.gap-4 {
  gap: 1.5rem !important;
}
.gap-5 {
  gap: 3rem !important;
}

.full-width{
  width: 100%;
}

#app-body{
  min-height: 100%;
}

.app-name{
  background: #7339AD !important;
}

.app-name:hover{
  background: #9248DC !important;
}

.align-form-control {
  margin-left: 12px;
}

md-switch {
  margin: 0;
}

md-switch.legend .label {
  width:140px;
  display:inline-block;
  padding-top:6px;
  padding-bottom:6px;
  margin:2px;
}

.btn-dash {
  margin-bottom: 8px;
}


.centered{
  text-align: center;
}

.centered td, .centered th{
  text-align: center;
}

.fs-sm{
  height: 25px;
}

.table-container{
  margin-bottom: 200px;
}


.label-md-wfw{
  padding: 8px;
  padding-left: 15px;
  padding-right: 15px;
}
.label-md{
  font-size: 16px;
  margin-left:30px;
}

.form-control-static.label-content {
  line-height: 17px;
}

.label.button-like-label{
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
}

.label.inverted-primary{
  color: #337ab7;
  background-color: white;
  border: 1px solid #337ab7;
}

.newtemplate .form-control{
  width: 200px;
}

#display-current-rule .label{
  margin: 1px;
  margin-top: 10px;
  font-size: 0.9em;
}

.datetimepicker{
  th.left{
    outline: none;
  }
  th.right{
    outline: none;
  }
}

.no-outline{
  outline: none;
}

/* this class is used for highlighting the updated row when a list receive a ws message */
.fade-out-bg.ng-enter{
  transition: background-color 2s cubic-bezier(0, 0, 0, 0.66);
  background-color: #d9edf7;
}

.fade-out-bg.ng-enter-active{
  background-color: transparent;
}

.action-btn-bar {
  button:not(:last-child), .btn-group:not(:last-child), app-button{
    margin-right: 5px;
  }

  .btn-group button{
    margin-right: 0px;
  }
}

.modal-loading{
  .modal-dialog{
    .modal-content{
      background-color: initial!important;
      border: none!important;
      box-shadow: none!important;

    }
  }
}

.rotate-animation{
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.search-collapse-icon:not(.open){
  transform: rotate(-90deg);
}

.chevron-rotate.rotate{
  transform: rotate(90deg);
}

.hoverable{
  border-bottom: dashed 1px #aaa;
}

.btn-icon{
  border: none;
  outline: none !important;
  padding: 1px 6px;
  font-size: 21px;
  height: 35px;
  width: 35px;
}

.btn.btn-transparent, .btn-transparent{
  background-color: transparent;
  border: none;
  outline: none;
  &:hover{
    background-color: #e6e6e6;
  }
}

.wcm-fa-btn{
  outline: none;
  cursor: pointer;

  &[disabled]{
    cursor: default;
  }
}

.padded-icon{
  padding-right: 5px;
}

.inline-btn-group{
  display: inline-block;
}

md-checkbox.wcm-form-checkbox{
  margin-bottom: 0px;
  margin-left: 10px;
  display: block;

  &.inline{
    display: inline-block;
  }
}

.wcm-text-danger{
  color: red;
}

.wcm-text-success{
  color: green;
}

.wcm-text-warning{
  color: orange;
}

/* Detailed view navbar (breadcrumbs) */
.nav.navbar-nav.detail-view-nav{
  li{
    cursor: text;
  }
  li a{
    @media (min-width: @screen-sm-min) {
      padding-left: 0px;
      padding-right: 0px;
      margin: 0px 15px;
      display: inline-block;
    }
    color: #337ab7;
    font-size: 16px;
  }
  li a:hover{
    color: #23527c;
    text-decoration: underline;
  }
  li span.symbol{
    padding-left: 5px;
    padding-right: 5px;
    color: black;
    padding-top: 15px;
    padding-bottom: 15px;
    display: inline-block;
  }
}
@media (max-width: @screen-xs-max) {
  .wcm-erp{
    form.navbar-form.navbar-right{
      .dropdown-menu{
        position: static;
        float: none;
      }
      button{
        display: block;
        position: initial;
        float: initial;
        margin-top: 3px;
      }
      .btn-group{
        display: block;
        button{
          display: block;
          position: initial;
          float: initial;
          margin-top: 3px;
        }
      }
    }
    div.navbar-collapse > ul.nav.navbar-nav > li > a{
      float: left;
    }
  }
}

/* list view nav bar */
.wcm-list-navbar{
  .navbar-collapse.collapse.in{
    overflow-y: visible;
  }

  .navbar-form.navbar-right{
    border: 0px;
  }
}

/* st-pagination nav-bar */
@media (max-width: @screen-xs-max) {
  .small-paginate-bar{
    margin-top: 0px;
    margin-bottom: 0px;
    border: 0px;
  }
  .inline-sm-xs{
    display: inline-block !important;
    margin-bottom: 0px !important;
  }
}


/* Read only select for dynamic forms */
select.readonly-select[disabled]{
  background-color: #eee !important;
  border: 1px solid #ccc !important;
}

/* wcm-st-table */
wcm-st-table table{
  thead > tr > th[st-sort=""]{
    cursor: default;
  }
  tbody > tr > td.sub-table{
    padding: 0px;
    table{
      margin: 0px;
      background-color: transparent !important;
    }
  }

  tr td.reduced-padding{
    padding: 0px 8px;
    vertical-align: middle;
  }
}

/* wcm-st-table in a pop-up modal */
.modal-content wcm-st-table .wcm-st-table-body-wrapper{
  overflow-x: auto;
}

// for all tables (incl woi table)
tr.not-clickable{
  cursor: default;
  outline: none;
}

table tbody tr td.full-centered{
  vertical-align: middle;
  text-align: center;

  > .fa{
    vertical-align: middle;
  }
}

table tbody tr td.check-box-cell, table thead tr th.check-box-cell{
  vertical-align: middle;
  text-align: center;

  md-checkbox{
    margin-bottom: 0px;
    margin-left:10px;
  }
}

table.v-center tbody tr td{
  vertical-align: middle;
}

/* mall pagination element */
.small-pagination{
  text-align: center;

  .pagination > li > a{
    background-color: white;
    color: #333;
    border-color: #ccc;
  }

  .pagination > li > a:focus,
  .pagination > li > a:hover,
  .pagination > li > span:focus,
  .pagination > li > span:hover{
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
  }

  .pagination > .active > a{
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
  }

  .pagination > .active > a:hover{
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
  }
}

/* Suggestion toast */
#toast-container.suggestion-toast{
  div.toast{
    opacity: 1;
    width: 54px;
    height: 40px;
    padding-top: 10px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    div.toast-title, button{
      opacity: 0;
    }
  }
}

@media (min-width: @screen-sm-min) {
  #toast-container.suggestion-toast > div.toast:hover{
    -webkit-transition: width 0.2s ease-in-out;
    -moz-transition: width 0.2s ease-in-out;
    -o-transition: width 0.2s ease-in-out;
    transition: width 0.2s ease-in-out;
    opacity: 1;
    width: 190px;
    height: 40px;
    padding-top: 10px;
    padding-bottom: 0px;
    padding-left: 50px;
    padding-right: 15px;
    div.toast-title, button{
      -webkit-transition: opacity 0.2s 0.2s;
      -moz-transition: opacity 0.2s 0.2s;
      -o-transition: opacity 0.2s 0.2s;
      transition: opacity 0.2s 0.2s;
      opacity: 1;
    }
  }
}

/* toggle button */
button.btn.toggle-btn.btn-default{
  &.active{
    &:hover, &:focus{
      background-color: #e6e6e6 !important;
      border-color: #adadad !important;
    }
  }
  &:not(active){
    &:hover, &:focus{
      background-color: #fff !important;
      border-color: #ccc !important;
    }
  }
  outline: none !important;
}

.small-form-group{
  .form-group{
    margin-bottom: 5px;
  }
}

.small-label{
  .control-label{
    padding-top: 0px;
    margin: 0px;
  }
}

/* entities detail page */
.entities-detail{
  /* this line apply the .small-form-group here */
  .small-form-group;
  /* Entity map */
  .leaflet-top, .leaflet-bottom{
    z-index: auto;
  }
}

/* woi detail page */
.woi-detail{
  /* this line apply the .small-form-group here */
  .small-form-group;

  .tabset{
    margin-top: 10px;
  }

  .well{
    padding-left: 0px;
    padding-right: 0px;
  }

  .description-readonly{
    margin-bottom: 10px;
  }

  .wcm-jumbotron {
    background-color: #eee;
    padding: 15px;
    margin-bottom: 5px;
  }

  // XS only
  @media (max-width: @screen-xs-max){
    .wcm-jumbotron {
      margin-left: -5px;
      padding-left: 5px;
    }
  }
}

/* control label */
.control-label.left{
  text-align: left;
}

/* markdown input */
.markdown-readonly{
  overflow: auto;
  p{
    min-height: 34px;
    padding: 7px 12px;
    margin-bottom: 0;
  }
}

/* markdown preview and display */
.markdown-preview{
  height: 100%;
  background-color: #f3f3f3;
  overflow:auto
}
.markdown-preview, .markdown-display{
  p{
    margin-bottom: 5px;
  }
  li > p{
    margin: 0px;
  }
  h1, h2, h3, h4{
    margin: 0px;
  }
  h1{
    font-size: 24px;
  }
  h2{
    font-size: 22px;
  }
  h3{
    font-size: 20px;
  }
  h4{
    font-size: 18px;
  }
}
/* static input */
p.form-control-static{
  padding-left: 12px;
  padding-right: 12px;
}


/* column with same-height */
.equal-row {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
}


/* Snow */
body{
    background: transparent !important;
}

.snow canvas{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
}

/* readonlyinput that looks like disabled input */
.wcm-readonly[readonly] .form-control[readonly], .wcm-readonly.form-control[readonly]{
  box-shadow: none;
  border-color: transparent;
  background-color: transparent;
  cursor: default;
}

.wcm-readonly[readonly] .input-group-addon{
  display: none;
}

.wcm-1dot7x{
  font-size: 1.7em;
}

.wcm-1dot5x{
  font-size: 1.5em;
}

/* quote group*/
.quote-group{
  padding: 10px;

  tr.no-border-top, tr.no-border-top th{
    border-top: none;
  }

  tr.sub-header th{
    border-bottom: none;
  }

  .header-title{
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .group-header{
    outline: none;

    &.not-clickable{
      cursor: default;
    }
  }

  .description{
    color: #737373;
    padding-left: 15px;
  }

  table tr:last-child{
    border-bottom: #dddddd 2px solid;
  }
}

/* margin class for sm screen size only,
 used to add a margin between wrapped cols (when total col # > 12)*/
.margin-form-sm{
  @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
    margin-bottom: 15px;
  }
}

/* required form css */
label .required, uib-tab-heading .required, th .required{
 color: red;
}
.wcm-highlight-req.form-control.ng-empty[required],
.wcm-highlight-req.form-control.ng-invalid-required,
.wcm-highlight-req.nested-input.ng-invalid-required .form-control,
.wcm-highlight-req.form-control.ng-invalid-email[required],
.wcm-highlight-req.form-control.ng-invalid-pattern[required],
.wcm-highlight-req.ng-invalid-required .input-group.dropdown .form-control,
date-field.wcm-highlight-req.ng-invalid-required .form-control,
wcm-date-field.wcm-highlight-req.ng-invalid-required .form-control{
    &:not([disabled]):not([readonly]){
      border-color: #ce8483;

      &:focus{
        border-color: #843534;
        -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px #ce8483;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px #ce8483;
      }
  }
}

.wcm-highlight-change:not(.fa):not(.far):not(.fas):not([disabled]):not([readonly]){
  border-color: #3c763d;

  &:focus{
    border-color: #2b542c;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px #67b168;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px #67b168;
  }
}

/* specific case for fa radio btn */
.wcm-highlight-change:not([disabled]):not([readonly]){
  &.fa, &.far, &.fas{
    color: #3c763d;
  }
}

.error-message{
  display: block;
  margin-top: 5px;
  color: #a94442;
}

.input-message{
  display: block;
  margin-top: 5px;

  &.no-added-height{
    margin-bottom: -25px; // removing the element height from the bottom (line height should be 20px and margin top 5px)
  }
}

/* custom input group with button */
.wcm-input-group{
  display: flex;

  & > :first-child{
    flex: 1;
  }
  input{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  button{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

/* no-border tab */
.no-border-tab .tab-content .tab-pane{
  border: none;
}

.wcm-md-editor{
  margin-bottom: 15px;
  textarea{
    padding: 5px;
    background-color: white !important;
  }

  div.md-preview{
    padding: 5px;
  }

  /* override the preview button color to make it alternate between blue and white */
  .md-editor button.btn-primary[data-handler=bootstrap-markdown-cmdPreview]{
    color: #333;
    background-color: #fff;
    border-color: #ccc;

    &.active{
      color: #fff;
      background-color: #286090;
      border-color: #204d74;
    }
  }

  &.small .md-editor:not(.md-fullscreen-mode){
    button[data-handler=bootstrap-markdown-cmdBold], button[data-handler=bootstrap-markdown-cmdItalic],
    button[data-handler=bootstrap-markdown-cmdHeading], button[data-handler=bootstrap-markdown-cmdUrl],
    button[data-handler=bootstrap-markdown-cmdImage], button[data-handler=bootstrap-markdown-cmdListO],
    button[data-handler=bootstrap-markdown-cmdList], button[data-handler=bootstrap-markdown-cmdCode],
    button[data-handler=bootstrap-markdown-cmdQuote]{
      display: none;
    }
  }
}

/* login screen */
.login-screen{
  margin: 0px auto;
  width: 1170px;
  padding-top: 30vh;

  // XS only
  @media (max-width: @screen-xs-max){
    width: 100%;

    img{
      max-width: 100%;
    }
  }

  // SM only
  @media (max-width: @screen-sm-max) and (min-width: @screen-sm-min){
    width: 750px;
  }

  // MD only
  @media (max-width: @screen-md-max) and (min-width: @screen-md-min){
    width: 970px;
  }

  img{
    padding-bottom: 10px;
  }
}

// ES search
.es-search-bar{
  margin-bottom: 20px;
  @media (min-width: @screen-sm-min){
    margin-top: 50px;;
  }
}
.es-result:not(:last-child){
  padding-bottom: 15px;
}

// File upload drop box
.drop-box{
  background: #F8F8F8;
  border: 5px dashed #DDD;
  text-align: center;
  padding: 50px 10px;

  &.dragover:not(.disabled) {
    border-color: rgba(82,168,236,.8);
    box-shadow: 0 0 8px rgba(82,168,236,.6);
  }

  &.file-error:not(.disabled):not(.dragover) {
    border-color: rgba(132, 53, 52, 0.8);
    box-shadow: 0 0 8px rgba(206, 132, 131, 0.6)
  }

  &.disabled {
    cursor: not-allowed;
  }
}

table.file-table{
  tbody > tr > td{
    vertical-align: middle;
  }

  td.icon-cell i.fa{
    font-size: 150%;
  }
}

label.truncate-label{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.form-popover{
  position: fixed;
  z-index: 1025;
  padding: 8px;
  margin-top: 32px; // 30px for the button height and 2px below
  float: left;
  min-width: 200px;
  background-color: white;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  font-size: 14px;
  text-align: left;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
  box-shadow: 0 6px 12px rgba(0,0,0,.175);

  hr{
    margin: 5px 0px;
  }

  .form-group{
    margin-bottom: 5px;
  }
}

.table-dropdown{
  width: 100%;
  button.dropdown-toggle{
    width: 100%;
    outline: none !important;
    &:focus:not(.active){
      background-color: #fff;
      border-color: #ccc;
    }
  }
}

uib-tab-heading .badge{
  margin-left: 0px !important;
  margin-top: -4px !important;

  &.primary{
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;
  }
  &.success{
    color: #fff;
    background-color: #12A70E;
    border-color: #149410;
  }
  &.danger{
    color: #fff;
    background-color: #B80D1D;
    border-color: #8F0A16;
  }
}

/* progress bar */
.form-progress{
  padding-top: 4px;

  .progress {
    max-width: 200px;
  }
}

/* tags */
.wcm-tag-list{
  margin: 0;
  padding: 0;
  list-style-type: none;

  .wcm-tag-item {
    margin: 2px;
    padding: 0 5px;
    display: inline-block;
    float: left;
    font: 14px "Helvetica Neue", Helvetica, Arial, sans-serif;
    height: 26px;
    line-height: 25px;
    border: 1px solid #acacac;
    border-radius: 3px;
  }
}

/* Drag and drop */

.quote-group .group-header [dnd-handle]{
  margin-right: 5px;
}

.wcm-dnd-container[dnd-list] .dndDraggingSource{
    display: none;
}

.wcm-dnd-container[dnd-list]{
  .dndPlaceholder{
    background-color: #ddd;
    display: block;
    min-height: 46px;
  }

  tr.dndPlaceholder{
    background-color: #ddd;
    display: table-row;
  }
}

.wcm-dnd-container[dnd-list]{
  .wcm-dnd-item{
    cursor: -webkit-grab;

    &.btn-default:active, &.btn-default:hover, &.btn-default:focus{
      color: #333;
      background-color: #fff;
      border-color: #ccc;
      box-shadow: none;
    }
  }

  [dnd-draggable][dnd-disable-if][draggable=false] .wcm-dnd-item{
    cursor: default;
  }
}


.animate-repeat-enter{
  &.ng-enter {
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    transition: all 0.5s linear;
  }

  &.ng-enter {
    opacity: 0;
  }

  &.ng-enter.ng-enter-active {
    opacity:1;
  }
}
/* modal size on large devices (not defined in bootstrap css)*/
@media (min-width: 1200px) {
    .modal-lg {
        width: 1100px;
    }
}
/* TODO This is a bit ugly, maybe long-term use a ".modal-xl" class ? */
@media (min-width: 1500px) {
    .modal-lg {
      width: 1450px;
    }
}
@media (min-width: 2160px) {
    .modal-lg {
      width: 1900px;
    }
}

/* multiple selection action bar */
.modal-selection-bar-container{
  position: relative;
  height: 80px;
  overflow: hidden;

  .selection-action-bar{
    position: absolute;
  }
}

.selection-action-bar{
  position: fixed;
  bottom: 0px;
  left: 50%;
  -webkit-transform: translateX(-50%) translateZ(0px);
  -moz-transform: translateX(-50%) translateZ(0px);
  -o-transform: translateX(-50%)translateZ(0px);
  transform: translateX(-50%) translateZ(0px);
  padding: 15px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background: white;
  -moz-box-shadow: 0 0 12px #999999;
  -webkit-box-shadow: 0 0 12px #999999;
  box-shadow: 0 0 12px #999999;
  z-index: 2;

  &.animate-ng-hide{
    &.ng-hide-add,
    &.ng-hide-remove {
      -webkit-transition: all 0.5s;
      -moz-transition: all 0.5s;
      -o-transition: all 0.5s;
      transition: all 0.5s;
    }

    &.ng-hide{
      -webkit-transform: translate(-50%, 100%);
      -moz-transform: translate(-50%, 100%);
      -o-transform: translate(-50%, 100%);
      transform: translate(-50%, 100%);
    }
  }

  .bar-content{
    display: flex;
    align-items: center;

    & > *:not(:first-child){
      margin-left: 15px;
    }
  }
}

// prevent waiting for the animation to end
.fa.fa-spin.ng-hide{
  display: none;
}

/* product config prices */

.config-price-table{
  text-align: right;
  margin-right: 0px;
  margin-left: auto;

  tr td:not(:first-child){
    padding-left: 15px;
  }
}

.page-header.header-sm{
  margin-top: 20px;
}

.page-header.header-xs{
  margin-top: 10px;
  margin-bottom: 10px;
}

.header-title{
  border-bottom: #dddddd 1px solid;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 10px;

  &.nested{
    margin-top: 0px;
  }
}

/* permissions */
.permission-source{
  margin: 0px 3px 0px 10px
}

.groups-icon{
  margin-right: 10px;
}

.group-permissions{
  margin-bottom: 0px;
  margin-top: 5px;
  margin-left: 10px;
}

/* alerts */
.alert.alert-sm{
  margin-bottom: 5px;
}

.alert.btn-alert{
  padding: 10px 15px;
}

/* wizard */
.bs-wizard{
  border-bottom: solid 1px #e0e0e0;
  padding-bottom: 10px;
  margin-bottom: 30px
}
.bs-wizard > .bs-wizard-step{
  padding: 0;
  position: relative;
  .bs-wizard-label{
    color: #777;
    margin-bottom: 5px;
    line-height: 30px;
    white-space: nowrap;
  }
  &:first-child.active > .progress > .progress-bar{
    width: 0%;
  }
  &:last-child.active > .progress > .progress-bar{
    width: 0%;
  }
  &:last-child > .progress{
    width: 0%;
  }
}
.bs-wizard > .bs-wizard-step+.bs-wizard-step{
}
.bs-wizard > .bs-wizard-step > .bs-wizard-dot{
  border-color: transparent;
  border-width: 0px;
  background: #f5f5f5;
  border-radius: 50%;
  display: block;
  height: 30px;
  left: 50%;
  margin-left: -15px;
  margin-top: -15px;
  position: absolute;
  top: 55px;
  width: 30px;
  &:after{
    border-radius: 50px;
    content: ' ';
    height: 14px;
    left: 8px;
    position: absolute;
    top: 8px;
    width: 14px;
  }
}
.bs-wizard > .bs-wizard-step > .progress{
  left: 50%;
  border-radius: 0px;
  box-shadow: none;
  height: 8px;
  margin: 20px 0 !important;
  position: relative;
}
.bs-wizard > .bs-wizard-step > .progress > .progress-bar{
  background: #337ab7;
  box-shadow: none;
  width: 0px;
}
.bs-wizard > .bs-wizard-step.complete{
  & > .progress > .progress-bar{
    width: 100%;
  }
  & > a.bs-wizard-dot, & > .bs-wizard-dot{
    background: #337ab7;
    &:after{
      background: #ffffff;
    }
  }
  &.displayed > a.bs-wizard-dot, &.displayed > .bs-wizard-dot{
    background: #d9edf7;
    &:after{
      background: #337ab7
    }
  }
}
.bs-wizard > .bs-wizard-step.active > .bs-wizard-dot{
  background: #337ab7;
}
.bs-wizard > .bs-wizard-step.displayed > .bs-wizard-label{
  font-size: 150%;
}
.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot{
  background-color: #f5f5f5;
  &:after{
    opacity: 0;
  }
}
.bs-wizard > .bs-wizard-step.disabled{
  a.bs-wizard-dot, .bs-wizard-dot{
    pointer-events: none;
  }
}

/* entity merge & equipment model merge */
table.merge-table{

  &.centered-small-table{
    width: initial;
    max-width: initial;
    margin-left: auto;
    margin-right: auto;

    .input-group{
      min-width: 150px;
    }
  }

  tbody>tr>td{
    border: 0px;
    padding: 12px 8px;
    vertical-align: middle;
  }

  .input-group{
    width: 100%;
    .form-control[disabled],
    .form-control.disabled { /* this line is for the downgraded ng2 inputs */
      padding-left: 0px;
      box-shadow: none;
      border-color: transparent;
      background-color: transparent;
      cursor: default;
    }
  }

  md-radio-button{
    margin-bottom: 0px;
  }

  tr.category-header{
    border-bottom: 1px #ddd solid;
    &:not(:first-child) td{
      padding-top: 30px;
    }
  }

  td.title-cell{
    text-align: center;
    font-weight: bold;
  }

  td.label-cell{
    font-weight: bold;
  }

  td.col-border:not(:last-child){
    border-right: 1px #ddd solid;
  }

  td.centered-radio{
    text-align: center;
    & md-radio-group md-radio-button{
      display: inline;
    }
  }

  td.right-radio{
    text-align: right;
    & md-radio-group md-radio-button{
      display: inline;
    }
  }

  md-radio-group md-radio-button .md-container:before{
    display: none;
  }

  md-radio-group.md-focused md-radio-button .md-container{
    outline: -webkit-focus-ring-color auto 5px;
  }

}

/* color picker */
.form-group .colorpicker-circle{
  margin-top: 2px;
  cursor: pointer;

  &.disabled{
    cursor: not-allowed;
    opacity: 0.5;
  }
}


/* entity wizard */
table.wizard-contact-table{
  md-radio-button{
    margin-bottom: 0px;
  }
  td.centered-radio{
    text-align: center;
    padding-top: 14px;
  }

  td.centered-btn {
    text-align: center;
    padding-top: 10px;
  }

  td.centered-radio md-radio-group md-radio-button{
    display: inline;

    & .md-container{
      left: 5px;
    }
  }
    md-radio-group md-radio-button .md-container:before{
    display: none;
  }

  md-radio-group.md-focused md-radio-button .md-container{
    outline: -webkit-focus-ring-color auto 5px;
  }
}

/* adblock warning */
.adblock-nag-container{
  position: fixed;
  bottom: 18px;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 0px;
}

.comments-list{
  margin-bottom: 15px;
  img{
    max-width: 100%;
  }
}

/* network line */
.category-header{
  span {
    outline: none;
  }

  .fa{
    margin-right: 5px;
  }
}
.generated-config{
  padding: 15px;

  .copy-btn{
    position: absolute;
    right: 5px;
    top: 5px;
    background-color: rgba(255, 255, 255, 0.25);
    color: #fff;
  }
}

.input-group.wcm-default-input-group {

  .form-control {
    padding: 5px;
    text-align: right;
  }

  .input-group-addon {
    background-color: #fff;
    padding: 5px 7px;
  }
}


.form-group.wcm-flexible-form {
  margin-bottom: 0px;

  .wcm-flexile-form-element {
    margin-bottom: 5px;
  }
}

table.table.wcm-invisible-table {
  tbody>tr>td{
    border: 0px;
    padding: 12px 8px;
    vertical-align: middle;
  }
}

table.setiar-table{
  tbody>tr>td {
    padding-top: 0px !important;

    &:first-child{
      white-space: nowrap;
      width: 1%;
      font-weight: 700;
      text-align: right;

      @media (max-width: @screen-xs-max) {
        min-width: 170px;
      }
    }
  }
}

/* custom readonly form-control-static*/
.form-control-static.greyed-readonly{
  background-color: #eee;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 6px 12px;
}

/* Custom readonly style for the wcm-date field */
wcm-date-field.greyed-readonly .form-control-static{
  .form-control-static.greyed-readonly
}

#particles-js {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -10;
    top: 0;
    left: 0
}

/* custom textarea like style */
.form-control.textarea-like {
  height: 114px; /*(5 rows + border + padding)*/
  overflow-y: auto;
  resize: vertical;
}

/* Christmas sled */
img.christmas-sled{
  position: fixed;
  height: 180px;
  transform: rotate(-15deg);
  top: -29px;
  right: -200px;
  display: none;
  z-index: 10;
  outline: none;
}

/* Fix calendar not displaying correctly after updating primeng to v14 (from 12) */
.p-calendar .p-datepicker.p-component.p-datepicker-inline {
  width: inherit !important;
}

/* Apparently upgrading to PrimeNG 14 changed the z-index of the modal backdrop... trying to get it to play nice */
ngb-modal-backdrop {
  z-index: 999 !important;
}

/* Hide the ngbtypeahead "N results available" div */
.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute!important;
  width: 1px!important;
  height: 1px!important;
  padding: 0!important;
  margin: -1px!important;
  overflow: hidden!important;
  clip: rect(0,0,0,0)!important;
  white-space: nowrap!important;
  border: 0!important;
}
