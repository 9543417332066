.bootstrap4-popover {
  /* removing bootstrap 3 css */
  &.popover {
    &::before, &::after {
      content: none;
    }

    box-shadow: unset;
  }

  &.popover .arrow {
    border-width: unset;
    border-color: unset;
    border-style: unset;
  }

  /* Adding bootstrap 4 css */
  &.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1070;
    display: block;
    max-width: 276px;
    font-family: 'Lato', 'Open Sans',  Times, sans-serif, serif;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }

  &.popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem;
  }

  &.popover .arrow::before, &.popover .arrow::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
  }

  &.bs-popover-top, &.bs-popover-auto[x-placement^="top"] {
    margin-bottom: 0.5rem;
  }

  &.bs-popover-top > .arrow, &.bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px);
  }

  &.bs-popover-top > .arrow::before, &.bs-popover-auto[x-placement^="top"] > .arrow::before {
    bottom: 0;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: rgba(0, 0, 0, 0.25);
  }

  &.bs-popover-top > .arrow::after, &.bs-popover-auto[x-placement^="top"] > .arrow::after {
    bottom: 1px;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #fff;
  }

  &.bs-popover-right, &.bs-popover-auto[x-placement^="right"] {
    margin-left: 0.5rem;
  }

  &.bs-popover-right > .arrow, &.bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
  }

  &.bs-popover-right > .arrow::before, &.bs-popover-auto[x-placement^="right"] > .arrow::before {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: rgba(0, 0, 0, 0.25);
  }

  &.bs-popover-right > .arrow::after, &.bs-popover-auto[x-placement^="right"] > .arrow::after {
    left: 1px;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #fff;
  }

  &.bs-popover-bottom, &.bs-popover-auto[x-placement^="bottom"] {
    margin-top: 0.5rem;
  }

  &.bs-popover-bottom > .arrow, &.bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px);
  }

  &.bs-popover-bottom > .arrow::before, &.bs-popover-auto[x-placement^="bottom"] > .arrow::before {
    top: 0;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: rgba(0, 0, 0, 0.25);
  }

  &.bs-popover-bottom > .arrow::after, &.bs-popover-auto[x-placement^="bottom"] > .arrow::after {
    top: 1px;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #fff;
  }

  &.bs-popover-bottom .popover-header::before, &.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7;
  }

  &.bs-popover-left, &.bs-popover-auto[x-placement^="left"] {
    margin-right: 0.5rem;
  }

  &.bs-popover-left > .arrow, &.bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
  }

  &.bs-popover-left > .arrow::before, &.bs-popover-auto[x-placement^="left"] > .arrow::before {
    right: 0;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: rgba(0, 0, 0, 0.25);
  }

  &.bs-popover-left > .arrow::after, &.bs-popover-auto[x-placement^="left"] > .arrow::after {
    right: 1px;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #fff;
  }

  .popover-header {
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
  }

  .popover-header:empty {
    display: none;
  }

  .popover-body {
    padding: 0.5rem 0.75rem;
    color: #333333;
  }
}

ngb-popover-window.bootstrap4-popover {
  /* ngBootstrap css redefined here to have priority over the bootstrap 4 css redeclared */
  &.bs-popover-bottom>.arrow, &.bs-popover-top>.arrow{
    left:50%;
    margin-left:-.5rem
  }
  &.bs-popover-bottom-left>.arrow, &.bs-popover-top-left>.arrow{
    left:2em
  }
  &.bs-popover-bottom-right>.arrow, &.bs-popover-top-right>.arrow{
    left:auto;
    right:2em
  }
  &.bs-popover-left>.arrow, &.bs-popover-right>.arrow{
    top:50%;
    margin-top:-.5rem
  }
  &.bs-popover-left-top>.arrow, &.bs-popover-right-top>.arrow{
    top:.7em
  }
  &.bs-popover-left-bottom>.arrow, &.bs-popover-right-bottom>.arrow{
    top:auto;
    bottom:.7em
  }
}
