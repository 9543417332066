html,
body {
    background-color: white;

    .p-component-overlay {
        background-color: rgba(0, 0, 0, 0.1);
    }
}

body, input, textarea, select
{
    font-family: 'Lato', 'Open Sans',  Times, sans-serif, serif;
    font-weight: 400;
}


.wcm-color
{
    color: #c5d201;
}

.unauth .jumbotron
{
    margin-top: 10%;
    background-color: rgba(39, 39, 39, 0.9);
    box-shadow: 0px 0px 30px rgba(30, 30, 30, 1);
}

.content
{
    margin-top: 20px;
    margin-bottom: 20px;
}
.unauth .btn
{
    box-shadow: 0px 0px 30px rgba(30, 30, 30, 1);
}
.unauth #logo
{
    margin:25px;
    margin-bottom:100px;
}

.content {
    margin-left: 5%;
    margin-right: 5%;
}

/*********************************************
                Spinner
/********************************************/

.spinner
{
    margin: 20% auto 0 auto;
    left: auto;
    top: auto !important;
}



/*********************************************
                    Menu
/********************************************/

.app-name
{
    margin-right: 10px;
    font-size: 15px;
    text-transform: uppercase;
    color: white !important;
}

.eva
{
    background: #7A9816 !important;
}
.eva:hover
{
    background: #98B81A !important;
}
#topLevelNavbar ul
{
    letter-spacing: 1.1px;
    font-size: 13px;
}
.navbar {
    border-radius: 0;
    margin-bottom: 0;
}
.navbar-brand.logo {
    padding: 5px;
}
.navbar-brand img
{
    margin-top: 4px;
    height: 40px;
    width: auto;
    margin-right: 0px;
}

div.navbar .pagination
{
  margin-top: 0px;
  margin-bottom: 0px;
}

.navbar-nav > li .dropdown-menu
{
    background-color: #393939;
    color:white;
}

#wcm-header .dropdown-menu > li > a {
    color:#999999;
}

@media (min-width:768px) {
    .navbar-right {
        margin-left: 20px;
    }

}

.navbar-nav button {
    margin-top: 10px;
}

.user-account-label {
    color: #9d9d9d;
    position: relative;
    margin-left: 50px;
}

.user-account-label:hover {
    color: white;
    text-decoration: none;
}

.user-account-label:visited {
    color: white;
    text-decoration: none;
}

.user-account-label img {
    height: 45px;
    border: solid 1px #636363;
    padding: 5px;
    border-radius: 20%;
    position: absolute;
    left: -45px;
    top: 3px;
}

.avatar-img {
    height: 45px;
    border: solid 1px #c5c5c5;
    border-radius: 10%;
    box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.7);
}

.nohover, .nohover:hover
{
    color: #999999 !important;
    background-color: transparent !important;
}
.menu-icon
{
    margin-right: 20px;
}

/*********************************************
                    SubMenu
/********************************************/
.navbar.subnavbar {
    min-height: 0;
    border: none;
}
@media (min-width: 768px) {
    .submenu {
        margin-left: 345px;
    }
}
.submenu > ul > li > a {
    min-height: 30px;
    padding-top: 6px;
    padding-bottom: 6px;
}


/* Back to top */
.totop
{
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 104400;
    background: #96C11E;
    display: none;
}

.totop a, .totop a:visited
{
    display: block;
    width: 50px;
    height: 50px;
    color: #fff;
    text-align: center;
    line-height: 50px;
}

.totop a:hover
{
    color: #848484;
    background: #8BB01B;
    text-decoration: none;
}

.loginblock
{
    float:right;
    margin-right: 1%;
}

/*********************************************
        |   Bootstrap extra row col
/********************************************/
.col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4,
.col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8,
.col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12 {
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
}

@media (min-width: 2200px) {
    .col-xl-1,
    .col-xl-2,
    .col-xl-3,
    .col-xl-4,
    .col-xl-5,
    .col-xl-6,
    .col-xl-7,
    .col-xl-8,
    .col-xl-9,
    .col-xl-10,
    .col-xl-11 {
        float: left;
    }

    .col-xl-1 {
        width: 8.333333333333332%;
    }

    .col-xl-2 {
        width: 16.666666666666664%;
    }

    .col-xl-3 {
        width: 25%;
    }

    .col-xl-4 {
        width: 33.33333333333333%;
    }

    .col-xl-5 {
        width: 41.66666666666667%;
    }

    .col-xl-6 {
        width: 50%;
    }

    .col-xl-7 {
        width: 58.333333333333336%;
    }

    .col-xl-8 {
        width: 66.66666666666666%;
    }

    .col-xl-9 {
        width: 75%;
    }

    .col-xl-10 {
        width: 83.33333333333334%;
    }

    .col-xl-11 {
        width: 91.66666666666666%;
    }

    .col-xl-12 {
        width: 100%;
    }

    .col-xl-push-1 {
        left: 8.333333333333332%;
    }

    .col-xl-push-2 {
        left: 16.666666666666664%;
    }

    .col-xl-push-3 {
        left: 25%;
    }

    .col-xss-push-4 {
        left: 33.33333333333333%;
    }

    .col-xl-push-5 {
        left: 41.66666666666667%;
    }

    .col-xl-push-6 {
        left: 50%;
    }

    .col-xl-push-7 {
        left: 58.333333333333336%;
    }

    .col-xl-push-8 {
        left: 66.66666666666666%;
    }

    .col-xl-push-9 {
        left: 75%;
    }

    .col-xl-push-10 {
       left: 83.33333333333334%;
    }

    .col-xl-push-11 {
       left: 91.66666666666666%;
    }

    .col-xl-pull-1 {
        right: 8.333333333333332%;
    }

    .col-xl-pull-2 {
        right: 16.666666666666664%;
    }

    .col-xl-pull-3 {
        right: 25%;
    }

    .col-xl-pull-4 {
        right: 33.33333333333333%;
    }

    .col-xl-pull-5 {
        right: 41.66666666666667%;
    }

    .col-xl-pull-6 {
        right: 50%;
    }

    .col-xl-pull-7 {
        right: 58.333333333333336%;
    }

    .col-xl-pull-8 {
        right: 66.66666666666666%;
    }

    .col-xl-pull-9 {
        right: 75%;
    }

    .col-xl-pull-10 {
        right: 83.33333333333334%;
    }

    .col-xl-pull-11 {
        right: 91.66666666666666%;
    }

    .col-xl-offset-1 {
        margin-left: 8.333333333333332%;
    }

    .col-xl-offset-2 {
        margin-left: 16.666666666666664%;
    }

    .col-xl-offset-3 {
        margin-left: 25%;
    }

    .col-xl-offset-4 {
        margin-left: 33.33333333333333%;
    }

    .col-xl-offset-5 {
        margin-left: 41.66666666666667%;
    }

    .col-xl-offset-6 {
        margin-left: 50%;
    }

    .col-xl-offset-7 {
        margin-left: 58.333333333333336%;
    }

    .col-xl-offset-8 {
        margin-left: 66.66666666666666%;
    }

    .col-xl-offset-9 {
        margin-left: 75%;
    }

    .col-xl-offset-10 {
        margin-left: 83.33333333333334%;
    }

    .col-xl-offset-11 {
        margin-left: 91.66666666666666%;
    }
}

/*********************************************
                    API CHECKER
/********************************************/

.apichecker {
    .nav.navbar-nav {
        margin-right: 0px;
    }

    .connected {
        color: #5cb85c;
    }

    .error {
        color: #ff3c3c;
    }

    code {
        color: inherit;
        background: none;
        padding: none;
        border: none;
    }
}

/*********************************************
                    AngularJS
/********************************************/
[ng-click],
[data-ng-click],
[x-ng-click] {
    cursor: pointer;
}


/*********************************************
                    Table
/********************************************/

.input-sm
{
    box-sizing: border-box !important;
    -webkit-box-sizing: border-box !important;
}
small
{
    color:gray;
    font-size: 12px;
}

.centered
{
  text-align: center;
}

.centered td, .centered th
{
  text-align: center;
}

.st-selected
{
  background: rgba(33,110,255,0.2) !important;
  color: black !important;
  font-weight: bold;
}
th[st-sort]
{
    cursor: pointer;
}
.expandable:focus
{
   outline: none;
}

.wcm-pagination .pagination
{
    margin: 0px !important;
    padding: 0px !important;
}

// Replace the default sorting characters by ones that make more sense (from smallest to biggest or biggest to smallest)
.pi-sort-amount-up-alt:before {
  content: '\e99f';
}
.pi-sort-amount-down:before {
  content: '\e9a0';
}

.disabled td {
    color: #c6c6c6;
}
.disabled td * {
    color: #c6c6c6;
}


/*********************************************
                    Components
/********************************************/
.label-pill {
  padding-right: .6em;
  padding-left: .6em;
  border-radius: 10rem;
}

.input-group-addon.primary {
    color: rgb(255, 255, 255);
    background-color: rgb(50, 118, 177);
    border-color: rgb(40, 94, 142);
}
.input-group-addon.success {
    color: rgb(255, 255, 255);
    background-color: rgb(92, 184, 92);
    border-color: rgb(76, 174, 76);
}
.input-group-addon.info {
    color: rgb(255, 255, 255);
    background-color: rgb(57, 179, 215);
    border-color: rgb(38, 154, 188);
}
.input-group-addon.warning {
    color: rgb(255, 255, 255);
    background-color: rgb(240, 173, 78);
    border-color: rgb(238, 162, 54);
}
.input-group-addon.danger {
    color: rgb(255, 255, 255);
    background-color: rgb(217, 83, 79);
    border-color: rgb(212, 63, 58);
}

.sw-md
{
    font-size: 30px;
}

.wcm-field-bloc {
    margin-bottom: 10px;
}

.wcm-field-bloc > div:first-child {
    text-align: right;
    font-weight: bold;
}

.wcm-field-bloc > form {
    margin-top: -4px;
}

/*
 * Dashboard
 */

.dashboard .jumbotron,
.dashboard .dashboard-news-entry {
    position: relative;
}

.dashboard .dashboard-toolbox {
    position: absolute;
    right: 1em;
}

.dashboard .dashboard-body {
    margin-bottom: 1.5em;
}

.dashboard .dashboard-body p {
    font-weight: 300;
}

.dashboard .dashboard-news-entry .header {
    margin-top: 1em;
}

.dashboard .dashboard-news-entry .header > div {
    float: left;
}

.dashboard .dashboard-news-entry .header:after {
    content: " ";
    display: table;
    clear: both;
}

.dashboard .dashboard-news-entry .header-avatar {
    height: 46px;
    width: 46px;
}

.dashboard .dashboard-news-entry .header-avatar img {
    max-height: 100%;
    max-width: 100%;
}

.dashboard .dashboard-news-entry .header-text {
    padding-left: 1em;
}

.dashboard .dashboard-news-entry .header h2 {
    font-size: 24px;
    margin: 0;
}

.dashboard .dashboard-news-entry .header p {
    padding-left: 0.5em;
    color: gray;
}

.dashboard .dashboard-news-entry hr {
    width: 65%;
    margin: 0 auto;
    margin-top: 1em;
}

.modal-dashboard .quill-container,
.modal-news-entry .quill-container {
    min-width: 500px;
    min-height: 250px;
}

/*
 * Button overrides
 * ----------------
 */

.btn.text-left {
  text-align: left;

  // Fix caret position when button spans over all available horizontal space
  &.btn-block {
    .caret {
      position: absolute;
      right: 10px;
      top: 40%;
    }
  }
}

/*
used to colour the shipping (/ups) status metadata column, using the same colours as wgp
*/
.shipping-notice {
    background-color: #a0bcdc;
}
.shipping-standby {
    background-color: #e3931c;
}
.shipping-success {
    background-color: #5cb85c;
}
.shipping-error {
    background-color: #d9534f;
}


/*********************************************
                    PrimeNG
/********************************************/

body .p-inputswitch .p-inputswitch-slider:before {
  content: '';
  position: absolute;
  top: 3.5px;
}

body .p-timepicker>div {
  margin-left: 0.5em;
  min-width: 1.5em;
}
