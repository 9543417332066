p-table.wcm-table {

  *:not(.pi):not(.fa):not(.far):not(.fas) {
    font-family: Lato;
  }

  .p-component-overlay {
    align-items: flex-start;
    padding-top: 90px;

    .p-datatable-loading-icon {
      font-size: 3em;
    }
  }

  /* override the primeNG theme for table */
  .p-datatable {
    .p-datatable-caption {
      background-color: transparent;
      border: none;
    }

    .p-datatable-thead > tr > th {
      background-color: transparent;
      border: none;
      border-bottom: 2px solid #ddd;
      outline: none;

      &.p-sortable-column {
        &.p-highlight{
          background-color: #e7e7e7;
          color: #333333;
          outline: none;
        }

        .header-sort-icon-container {
          align-self: center;
          margin-left: 5px;
        }

        .p-sortable-column-icon {
          color: #e7e7e7;

          &.pi-sort-up,
          &.pi-sort-down,
          &.pi-sort-amount-up-alt,
          &.pi-sort-amount-down-alt,
          &.pi-sort-amount-up,
          &.pi-sort-amount-down {
            color: #848484;
          }
        }
      }
    }

    .p-datatable-wrapper {
      background:
      /* Shadow covers */
      linear-gradient(.25turn, white, white 2%, transparent 5%, transparent 95%, white 98%, white),

      /* Shadows */
      linear-gradient(.25turn, rgba(0,0,0,0.2), transparent 2%, transparent 98%, rgba(0,0,0,0.2));
      background-repeat: no-repeat;
      background-attachment: local, scroll;
    }

    .p-datatable-tbody, .p-datatable-tfoot {
      tr, tr:nth-child(even) {
        background-color: transparent;
        outline: none;

        td {
          padding: 8px;
          line-height: 1.42857143;
          vertical-align: top;
          border: 0px;
          border-top: 1px solid #ddd;
          font-weight: inherit;

          &.sm-v-padding {
            padding-top: 2px;
            padding-bottom: 2px;
          }

          &.v-center {
            vertical-align: middle;
          }
        }
        &.expanded, &.expanded:hover {
          background-color: rgba(51, 122, 183, 0.03);
        }
        &.row-expansion:hover {
          background-color: transparent;
        }
      }
    }

    .p-datatable-tbody > tr:hover {
      background-color: #f5f5f5;
    }

    .p-paginator{
      margin: 15px;
      padding: 8px;
      background-color: #f8f8f8;
      border: none;

      .p-paginator-pages .p-paginator-page{
        padding: 0px 3px;
        &.p-highlight {
          color: #555;
          background-color: #e7e7e7;
        }
      }
    }

    /*
    force wcm-table filter inputs to have the same appearance in 'readonly' mode as normal
    */
    .form-control.show-readonly[readonly],
    .form-control.show-readonly.readonly,
    .form-control.show-readonly[disabled],
    .form-control.show-readonly.disabled {
      border: 1px solid #ccc;
      box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    }
  }

  /* handle the resizable table css from primeNG : they set the overflow of the tr and td to none
   this breaks the dropdown */
  .p-datatable-resizable {
    .p-datatable-thead > tr > th,
    .p-datatable-tfoot > tr > td,
    .p-datatable-tbody > tr > td {
      overflow: initial;
    }
  }
}

.wcm-table-header {
  margin: 15px 0;

  nav {
    background-color: #f4f4f4;
    border-color: #f4f4f4;

    .navbar-collapse.collapse.in{
      overflow-y: visible;
    }

    .navbar-form {
      &.navbar-right, &.navbar-left{
        border: 0px;
      }
    }

    .col-toggle, .dropdown-toggle-no-blur{
      // This prevent the blur for the dropdown content in chrome
      &.dropdown {
        will-change: auto !important;

        // This prevent breaking the display when the dropdown menu is opened
        &.show {
          display: inline-block !important;
        }
      }

      .dropdown-menu{
        max-height: 450px;
        overflow-y: auto;

        .form-control {
          width: 100%;
          margin-top: -6px;
          border: 0px;
          border-radius: 0px;
          border-bottom: 1px solid #ccc;

          &:focus {
            box-shadow: none;
          }
        }
      }
    }

  }
}
